.field{
    margin:5px;
    padding-bottom: 5px;
    border: 1px solid #5e5e5e;
    background:#fcf8e3;
}
.field-group{
    margin:5px;
    background: #c4e3f3;
}

.html-tag {
    border: 1px #000 solid;
    background: #ddd;
}

.row {
    margin-bottom: 10px;
}

.bottom-btn-group {
    position: fixed;
    bottom: 0;
}

.alias-tag {
    display: inline-block;
    margin: 5px;
    border: 1px solid #000;
    background: #ffd5a1;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 12px;
}

.alias-tag .close {
    margin: 0 5px;
    font-size: 16px;
}

.content { 
    margin:0 auto; border-top:none; background-color:#FFFFFF; overflow:hidden; 
}
.toggle { 
    width:100%;
}
.toggle dl dt { 
    background:#F4FFF4 no-repeat scroll 8px 14px; font-size:16px; font-weight:bold; color:#FF7F50; cursor:pointer; margin:8px 0; padding-left:25px; display:block; 
}
.toggle dl dt.current { 
    background:#F4FFF4 no-repeat scroll 8px 14px; 
}
.toggle dl dd { 
    padding-left:10px; line-height:24px; 
}