@charset "utf-8";
/**Style Reset**/
body, div, span, h1, h2, h3, h4, h5, h6, p, pre, sup, sub, ul, ol, li, dl, dt, dd, form, fieldset, input, button, textarea, select, iframe, img, a, header, footer, section, article, aside, details, figcaption, figure, hgroup, nav, menu, canvas { padding: 0; margin: 0; }
body { background-color: #fff; color: #000; font-size:12px; font-family: "Microsoft Yahei", Tahoma, Arial, "Helvetica Neue", "Hiragino Sans GB", Simsun, sans-self; }

/*ul, ol { list-style-type: none; }*/
b, strong { font-weight: normal; }
i, em { font-style: normal; }
a { text-decoration: none; color: #333; cursor: pointer; }
/*清除浮动*/
.clearfix:after { clear: both; display: block; height: 0; content: ""; }
/*主体部分*/
.contains { width: 1190px; margin: 0 auto; display: block;}
/*面包屑导航*/
.crumbNav { padding: 18px 0; color: #323232; }
.crumbNav font { padding: 0 2px; font-family: simsun; }
/**选择商品分类**/
.wareSort-ml{
    position:relative;
}
.wareSort {
    padding: 15px 8px 15px 7px; border: 1px solid #ddd; background-color: #f6f6f6;
    width:100%;
    overflow-y: auto;
}
.wareSort ul {float:left;  width: 290px; padding: 10px; border: 1px solid #ddd; margin-right: 7px; margin-left: 8px; background-color: #fff; }
.wareSort ul li a { display: block; padding-right: 25px; padding-left: 10px; border: 1px solid #fff; line-height: 28px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.wareSort ul li a:hover { color: #52bea6; }
.wareSort ul li.active a { border-color: #52bea6; background: #cefff4 no-repeat right center; color: #52bea6; }
.selectedSort { padding: 10px 15px; border: 1px solid #ed7f5a; margin-top: 10px; margin-bottom: 10px; background-color: #fff4f0; color: #ed7f5a; }
.selectedSort b { font-weight: bold; }
.selectedSort i font { font-family: simsun; }
.wareSortBtn {
    padding-bottom: 50px; text-align: center;
    position:absolute;
    top:-42px;
    right:0px;
}
.wareSortBtn input { width: 200px; height: 36px; border: 1px solid #ed7f5a; -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; background-color: #ed7f5a; color: #fff; }
.wareSortBtn input:hover { border-color: #d95459; background-color: #d95459; }
.wareSortBtn input:disabled { border-color: #ddd; background-color: #f6f6f6; color: #9a9a9a; cursor: default; }
.submit{
    position: fixed;
    left: 300px;
    bottom: 20px;
}
.box{
    border: solid 1px #033;
    background-color: #ddf;
    word-break: break-all;
    word-wrap: break-word;
}
.closed{
    color:#999999;
    font-size:10px;
}

.child-field{
    font-size:10px;
}

.slide-parent{
    list-style-type:none;
}

.slide-child{
    padding-left:20px;
}

.rule-action{
    font-size:10px;
}
.red{
    color:#f00;
    font-size:20px;
}
.field{
    max-height:200px;
    overflow-y: auto;
}
.mb-5{
    margin-bottom:5px;
}
.mb-10{
    margin-bottom:10px;
}
.mb-60{
    margin-bottom: 60px;
}
.field img{
    width:100%;
}
.bottom-buttons{
    position:fixed;
    bottom: 0;
}
.rotate-0{
    transform: rotate(0);
}
.rotate-90{
    transform: rotate(90deg);
}

.rotate-180{
    transform: rotate(180deg);
}

.rotate-270{
    transform: rotate(270deg);
}
.short-input{
    width:30px;
}
.rule-editor{
    background:#eea236;
    height:100%;
    width:auto;
}
.image-list-box{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:999999;
    padding:50px;
    background:rgba(0,0,0,.5);
}
.box-body{
    width:640px;
    height:400px;
    background:#fff;
    position:relative;
    margin:auto;
}
.image-list-container{
    height:300px;
    overflow-y: scroll;
}
.image-list-container:after{
    clear: both;
}
.image-container{
    width:100px;
    height:100px;
    float:left;
    position: relative;
    margin: 10px;
    filter:brightness(50%);
}
.image-container.active{
    filter: brightness(100%);
}
.image-selected{
    position:absolute;
    top:0;
    right:0;
    color:#00b7ee;
    font-size:20px;
}
.match-img{
    max-width:800px;
}

.uploader-container{
    width:200px;
    height:200px;
    position: relative;
    left:0;top: 0;
}
.uploader-pick{
    opacity: 0;
    height:100%;width:100%;
    text-align: center;
    z-index: 2;
}
.webuploader-pick{
    width:100%;
    height:100%;
}
.uploader-label{
    position: absolute;
    width:100%;
    height:20px;
    line-height: 20px;
    text-align: center;
    top:50%;
    bottom: 50%;
    left:0;
    margin-top:-1rem;
    z-index: 1;
    font-weight: 900;
    background-color:rgba(0,0,0,.5);
}
.thumb{
    background: #999999;
    border:1px solid #000000;
    padding:5px;
    width:100%;
    height:100%;
    position: absolute;
    left: 0;top: 0;
    z-index: 0;
}
.uploader-percent{
    width:100%;
    height:100%;
    position: absolute;
    right:0;
    top:0;
    background: #000;
    opacity:.5;
    z-index:1
}
body.lock-screen{
    overflow: hidden;
}

.pr{
    position:relative;
}

.modal-body{
    max-height: 300px;
    overflow-y: scroll;
}
/**goHomepage style**/
/*.goHomepage{*/
    /*border:1px solid #0071b7;*/
    /*border-radius:1rem;*/
    /*display:block;*/
    /*width:8rem;*/
    /*height:4rem;*/
    /*position:fixed;*/
    /*top:20%;*/
    /*right:0;*/
    /*font-size:14px;*/
    /*margin:0 auto;*/
    /*padding:1rem;*/
    /*z-index:10000;*/
/*}*/

/*.goHomepage:hover b{*/
    /*font-weight:900;*/
/*}*/

/*.goHomepage b{*/
    /*display:block;*/
    /*color:#0071b7;*/
/*}*/


/***模板上的btn groups****/

.my-btn-group{
    border-radius:1rem;
    position:fixed;
    top:20%;
    right:5%;
    font-size:14px;
    margin:0 auto;
    z-index:10000;
    cursor:pointer;
    padding:4px;
}


.my-btn{
    border:1px solid #0071b7;
    width:7rem;
    height:4rem;
    line-height:4rem;
    text-align:center;
}
.my-btn-group a:first-child .my-btn{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.my-btn-group a:last-child .my-btn{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.my-btn-group a{
    color:#0071b7;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
}